.success {
  color: var(--green) !important;
  background: var(--green-light) !important;
  border: 1px solid var(--green) !important;
  border-radius: 8px !important;
}

.error {
  color: var(--pink) !important;
  background: var(--pink-light) !important;
  border: 1px solid var(--pink) !important;
  border-radius: 8px !important;
}
