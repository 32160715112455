@font-face {
  font-family: 'Albra';
  src: url('/fonts/Albra-Light.otf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Albra';
  src: url('/fonts/Albra-Regular.otf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Albra';
  src: url('/fonts/Albra-Medium.otf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Albra';
  src: url('/fonts/Albra-Semi.otf');
  font-weight: 600;
}

@font-face {
  font-family: 'Albra';
  src: url('/fonts/Albra-Bold.otf');
  font-weight: 700;
}

@font-face {
  font-family: 'Albra';
  src: url('/fonts/Albra-Black.otf');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Bozon';
  src: url('/fonts/Bozon-Thin.otf');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Bozon';
  src: url('/fonts/Bozon-Light.otf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Bozon';
  src: url('/fonts/Bozon-Regular.otf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Bozon';
  src: url('/fonts/Bozon-Medium.otf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Bozon';
  src: url('/fonts/Bozon-Demi-Bold.otf');
  font-weight: 600;
}

@font-face {
  font-family: 'Bozon';
  src: url('/fonts/Bozon-Bold.otf');
  font-weight: 700;
}

@font-face {
  font-family: 'Bozon';
  src: url('/fonts/Bozon-Black.otf');
  font-weight: 800;
  font-style: normal;
}
