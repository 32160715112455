.layout {
  display: flex;
  min-width: 100vw;
  min-height: 100vh;

  &__brand {
    flex: 1;
    width: 100%;
    min-height: 100%;
    background-color: var(--background);
    padding: 32px 0;

    display: flex;
    flex-direction: column;

    & > span:first-of-type {
      margin-top: auto !important;
    }

    & > span:last-of-type {
      display: none !important;
    }

    & img {
      min-width: unset !important;
      width: unset !important;
    }
  }

  &__content {
    flex: 1;
    min-height: 100%;
    width: 100%;
    background-color: var(--background-secondary);

    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 32px;
    justify-content: center;
    align-items: center;
    padding-bottom: 3rem;

    & > * {
      width: 80%;
      text-align: center;
      margin: 0;
    }

    & * {
      max-width: 410px;
    }
  }
}

@media screen and (max-width: 800px) {
  .layout {
    flex-direction: column;
    height: '100%';

    &__brand {
      flex-direction: row-reverse;
      justify-content: space-around;

      max-height: 160px;

      padding: 16px;

      & > span:first-of-type {
        width: 65% !important;
        margin-bottom: auto !important;
      }

      & > span:nth-of-type(2) {
        display: block !important;
        margin-top: auto !important;
        width: 11% !important;
      }
    }
  }
}
