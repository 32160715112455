@import './fonts.scss';

html,
body {
  padding: 0;
  margin: 0;
  font-family: Bozon, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

:root {
  --primary: #5c068c;
  --background: #f3edf7;
  --background-light: #f5f0f8;
  --background-secondary: #fffbf0;
  --green: #338006;
  --green-light: #f0f9f6;
  --pink: #d61473;
  --pink-light: #fff1f3;
  --grey-one: #333;
}

h1 {
  font-family: Albra, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-size: 48px;
  font-weight: 600;
  margin: 1.5rem 0 1.5rem 0 !important;
  color: var(--primary);
  max-width: 600px !important;
  @media screen and (max-width: 800px) {
    font-size: 36px;
  }
}

p {
  font-family: Bozon, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-weight: 400;
  font-size: 14px;
  margin: 0;
  color: var(--grey-one);
  line-height: 1.15rem;

  &.content {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 32px;

  & * {
    text-align: center;
  }
}
}

.animate {
  animation: fadeIn linear 0.5s;
}

@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

.btn-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;

  & img:nth-of-type(2) {
    min-width: unset !important;
    width: unset !important;
  }
}